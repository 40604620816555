@import 'theme';

header {
  position: relative;
  z-index: 2;
}

.home__area-map {
  background:#fff;
  position:relative;
  .map {
    margin:0;

    @include element ('headings') {
      margin-bottom:4rem;

      h3 {
        @include responsive-font-size(2.75rem);

        @media screen and (min-width:1768px) {
          font-size: 2.5rem;
        }

        @media screen and (max-width:1768px) {
          font-size: 2.25rem;
        }

        @media screen and (max-width:1640px) {
          font-size: 2rem;
        }

        @media screen and (max-width:1280px) {
          font-size: 1.75rem;
        }
      }


      // @media screen and (max-width:1450px) {
      //   width:46%;
      // }

      // @media screen and (max-width:1150px) {
      //   width: 49%;
      // }

      @media screen and (max-width:934px) {
        margin-bottom:9rem;
      }

      @media screen and (max-width:789px) {
        margin-bottom:5rem;
        width:inherit;
      }

      &::after {
        content: '';
        width: 100%;
        height: 330px;
        display: block;
        margin-top: -3rem;
        margin-left: 0%;
        position: absolute;
        z-index: 2222;
        background-image: url('https://s3.us-east-1.amazonaws.com/uniqueheatingandcooling/UIC_van_mask.png?mtime=20190924082300');
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center, center;
        max-width: 400px;

        @media screen and (min-width:1640px) {
          margin-top:0em;
          max-width: 450px;
        }

        @media screen and (max-width:1640px) {
          margin-top:-1em;
          max-width: 450px;
        }

        @media screen and (max-width:1540px) {
          margin-top: -2rem;
          max-width: 350px;
        }

        @media screen and (max-width:1270px) {
          margin-top: -2rem;
          max-width: 300px;
        }

        @media screen and (max-width:1080px) {
          margin-top: -2rem;
        }

        @media screen and (max-width:789px) {
          width: 100%;
          margin-top: -4rem;
          margin-left: 0%;
          max-width: 300px;
        }
      }
    }
  }
}

.home__banner {
  position:relative;

  .banner {
    @include element('headings') {
      h1 {
        @include responsive-font-size(2.75rem);

        @media screen and (max-width:789px) {
          max-width: 600px;
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    display:flex;
    flex-direction:column;

    .banner {
      min-height: 0;
    }
  }
}

.home__events {
	position:relative;
  z-index: 20;
}

.home__introduction {
  margin-top:-5%;
	position: relative;
	text-align: center;
	z-index: 0;

	.introduction {

    &.introduction--greenBackground {
      background:none;
      clip-path:none;
      padding-top:0;

      @media (max-width:800px) {
        background: linear-gradient(180deg, rgba(90,144,73,1) 0%, rgba(63,120,57,1) 100%);
      }
    }

    @include element('content') {
      padding-top:2em;
      @media screen and (max-width:1000px) {
        padding: 1rem 2rem 4rem 2rem;
      }
    }
	}

	p {
    @include rfs(1.5rem);
		font-weight: $global__weight--bold;
    margin-top: 2em;

    @media screen and (max-width:800px) {
      @include rfs(1rem);
    }
  }


  @media screen and (max-width:800px) {
    margin-top:0;

    .introduction {
      padding-top:0;

      @include element('content') {
        box-sizing: border-box;
        padding: 2rem 2rem 4rem 2rem;

        .button {

          @media screen and (max-width:960px) {
            padding: 0.5em 2em 0.5em 1em;
          }
        }
      }

      p {
        line-height:1.85em;
        margin-bottom: 1.2em;
      }
    }

    p {
      margin-top:inherit;
    }
  }
}

.home__our-partners {
  background-color: color('background');
  position: relative;

  .our-partners {
    margin: 0 auto;
    padding: 2em 25px;
    text-align:center;

    @include element('hr') {
      margin: 1em auto;
      max-width: 135px;
    }

    @include element('img') {
      place-self: center;
    }

    @include element('img-container') {
      display: grid;
      gap: 25px;
      grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      grid-template-rows: auto;
    }

    @media screen and (min-width:1080px) {
      padding: 4em 4vw;
    }
  }
}

.home__testimonials {
  margin:4em 0 -4em;

  @media screen and (max-width:798px) {
    margin: 0;
  }

  .testimonials {

    @media screen and (max-width:798px) {
      padding:2rem;
    }
  }

}

.services__service.servicesHeating .button {
  background-color:color('orange', 600);
  border-color: color('orange', 600);
  color:color('background');

  &:after {
    border-bottom:0.5em solid color('background');
  }

  &:hover, &:focus {
    background-color:color('orange');
  }
}

.services__service.servicesCooling .button {
  background-color:color('secondary');
  border-color: color('secondary');
  color:color('background');

  &:after {
    border-bottom:0.5em solid color('background');
  }

  &:hover, &:focus {
    background-color:color('blue');
  }
}
